.nav {
    height: 100vh;
}

.nav-link {
    display: flex;
    align-items: center;
    padding: 6px 0;
    font-weight: 300;
    margin-bottom: 7px;
    .nav-icon {
        opacity: 0.5;
        font-size: 20px;
        width: 25px;
    }
    &.active {
        font-weight: bold;
        .nav-icon {
            opacity: 1;
        }
    }
}

.dark-mode {
    .nav-icons {
      &.active {
        color: white;
      }
    }
}

.popup-modal {
    .popup-modal-content {
      background-color: rgba($color: white, $alpha: 0.85);
      width: 40vw;
      min-width: 500px;
      border-radius: 10px;
  
      &.dark {
        background-color: #2F2F2F;
      }
      
      .popup-modal-header {
        background-color: #008F85;
        color: white;
        padding: 20px 30px;
        border-radius: 10px 10px 0 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        .popup-modal-x {
          cursor: pointer;
          transition: all ease 0.2s;
          
          &:hover {
            color: #004251;
          }
        }
      }
  
      .popup-modal-form {
        padding: 20px 8%;
        overflow-y: auto;
        max-height: 90vh;
  
        .popup-modal-card {
          border-radius: 10px;
          padding: 20px;
          margin-bottom: 20px;
          background-color: white;
          transition: all ease 0.2s;
  
          &.dark {
            background-color: #2F2F2F;
            color: white;
          }
  
          &:hover {
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  
          }
  
          .popup-modal-rating {
            display: flex;
            align-items: center;
  
            small { width: 180px}
          }
  
          .popup-modal-textfield {
            &.dark {
              background-color: #2F2F2F;
              color: white;
            }
          }
  
          &.half {
            width: 48%;
            min-width: 200px;
          }
        }
      }
    }
}