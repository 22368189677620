$primary: #008F85;
$secondary: #004251;
$danger: #CD2C2C;
$gray: #E6E6E6;
$dark: #000A18;
$color-blind: #19168c;

.trip-modal {
  height: 80vh;
}

.trip-modal-inputs {
  width: 30%;
  height: 100%;
  border-radius: 0 4px 4px 0;
  padding: 5px 10px 0 15px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: auto;

  .trip-modal-form {
    height: 100%;
    padding-right: 10px;

    .trip-modal-destinations {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
  
      .trip-modal-list {
        position: absolute;
        background-color: white;
        z-index: 2;
        top: 63px;
        width: 101%;
        max-height: 30vh;
        overflow-y: auto;
        border: 1px solid $gray;
      }
    }

    .trip-modal-text-field {
      margin-top: 15px;
    }
  }

  &.eta {
    height: 91%;
  }
}

.trip-table-legends {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 20px 25px;
  border-radius: 10px;

  .trip-table-legend {
    margin-right: 2rem;
  
    .trip-table-legend-item {
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-weight: bold;
      width: 28px;
      height: 28px;
      border-radius: 4px;
      background-color: $primary;
      margin-right: 8px;

      &.paused { background-color: $danger;}
      &.planned { 
        background-color: rgba(255, 255, 255, 0); border: 1px solid $primary; color: $dark;
        &.color-blind-planned {
          border: 1px solid $color-blind;
        }
      }
      &.completed { background-color: #A4A4A4; color: $dark}
      &.color-blind-legend {
        background-color: $color-blind;
      }
    }
  }
}

.trip-modal-loading {
  position: absolute;
  background-color: rgba($color: white, $alpha: 0.5);
  z-index: 3;
  width: 97%;
  height: 96%;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.trip-modal-eta {
  position: absolute;
  text-align: center;
  color: white;
  font-weight: bold;
  width: 29%;
  z-index: 2;
  bottom: 16px;
  right: 17px;
  background-color: $primary;
  padding: 10px 0px;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.10);
}

.template-modal-cards {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  overflow-y: auto;
  max-height: 70vh;

  .template-modal-card {
    position: relative;
    width: 30%;
    margin-right: 20px;
    height: 100%;
    border-radius: 0 0 10px 10px;
    border: 2px solid $primary;
    cursor: pointer;
    margin-top: 25px;
    transition: ease all 0.2s;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);

    &:hover {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.30);
    }

    .template-modal-transparent {
      position: absolute;
      top: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
    }

    .template-modal-action {
      position: absolute;
      top: 10px;
      z-index: 3;
      padding: 5px;
      transition: ease all 0.2s;
      border-radius: 4px;
      background-color: rgba($color: white, $alpha: 0.8);
      
      &.delete {
        color: $danger;
        left: 10px;

        &:hover {
          background-color: $danger;
          color: white;
        }
      }

      &.edit {
        color: $primary;
        right: 10px;
        &:hover {
          background-color: $primary;
          color: white;
        }
      }
    }

    .template-modal-map {
      height: 250px;
    }
  
    .template-modal-card-name {
      color: white;
      padding: 7px;
      text-align: center;
      font-weight: bold;
      background-color: $primary;
      border-radius: 0 0 5px 5px;
    }
  }
  
  &.color-blind-eta{
    background-color: $color-blind;
  }
}