.text-field {
    margin-top: 20px !important;
}

.strength {
    margin-right: 5px;
    width: 50px;
    height: 5px;
    border-radius: 10px;
    background-color: rgb(184, 184, 184);
    opacity: 0.8;
}

.strength.very-weak {background-color: rgb(150, 0, 0);}
.strength.weak {background-color: red;}
.strength.medium {background-color: rgb(255, 208, 0);}
.strength.strong {background-color: rgb(142, 196, 42);}
.strength.very-strong {background-color: rgb(0, 185, 0);}