$primary: #008F85;
$secondary: #004251;
$dark: #000A18;
$lightGray: #F8F9FA;
$gray: #E6E6E6;
$danger: #CD2C2C;
$darkRed: #500200;
$color-blind: #19168c;

table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 15px;
}

table td, table th {
    border: 1px solid $gray;
    padding: 8px;
}

table tr:nth-child(even){background-color: $lightGray;}

table tbody tr:hover {background-color: $gray;}
table thead th:hover {background-color: $gray;}

table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    color: $dark; 
}

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.table-header-icon {
    opacity: 0.2;
    color: $dark;
}

.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.table-action-icon {
   color: $primary;
   transition: all ease 0.2s;
   &:hover {
    color: $secondary;
   }
   &.delete {
    color: $danger
   }
   &.color-blind-action{
    color: $color-blind;
        &:hover {
            color: $color-blind;
        }
        &.delete {
            color: $danger
        }
   }
}

.delete-user {
    color: $danger;
    transition: all ease 0.2s;
    cursor: pointer;
    &:hover {
        color: $darkRed
    }
}

.delete-user-conf {
    background-color: $danger;
    color: white;
    font-weight: 500;
    border-radius: 4px;
    transition: all ease 0.2s;
    cursor: pointer;
    &:hover {
        background-color: $darkRed;
    }
}

.darkmode .fa-sort, 
.darkmode .fa-sort-up, 
.darkmode .fa-sort-down {
    color: white;
}

.darkmode th{
    color: white;
    background: #2F2F2F;
    transition: all 0.3s;
}

.darkmode td{
    color: white;
    background: #2F2F2F;
}

.darkmode th:hover{
    background: #4c4c4c;
}