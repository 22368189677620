$primary: #008F85;
$secondary: #004251;
$darkGray: #808080;
$gray-200: #E6E6E6;

// Toggle
$background-color_1: var(--color-grey);
$background-color_2: #fff;
$background-color_3: var(--color-green);
$background-color_blind: var(--color-blind);

.switch {
	--button-width: 3.2em;
	--button-height: 1.7em;
	--toggle-diameter: 1.4em;
	--button-toggle-offset: calc((var(--button-height) - var(--toggle-diameter)) / 2);
	--toggle-shadow-offset: 10px;
	--toggle-wider: 3em;
	--color-grey: #cccccc;
	--color-green: #008F85;
	--color-blind: #19168c;
	input[type="checkbox"] {
		&:checked {
			+ {
				.slider {
					background-color: $background-color_3;
					&.color-blind-toggle{
						background-color: $background-color_blind;
					}
					&::after {
						transform: translateX(calc(var(--button-width) - var(--toggle-diameter) - var(--button-toggle-offset)));
						box-shadow: calc(var(--toggle-shadow-offset) * -1) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
					}
				}
			}
			&:active {
				+ {
					.slider {
						&::after {
							transform: translateX(calc(var(--button-width) - var(--toggle-wider) - var(--button-toggle-offset)));
						}
					}
				}
			}
		}
		display: none;
		&:active {
			+ {
				.slider {
					&::after {
						width: var(--toggle-wider);
					}
				}
			}
		}
	}
}
.slider {
	display: inline-block;
	width: var(--button-width);
	height: var(--button-height);
	background-color: $background-color_1;
	border-radius: calc(var(--button-height) / 2);
	position: relative;
	transition: 0.3s all ease-in-out;
	&::after {
		content: "";
		display: inline-block;
		width: var(--toggle-diameter);
		height: var(--toggle-diameter);
		background-color: $background-color_2;
		border-radius: calc(var(--toggle-diameter) / 2);
		position: absolute;
		top: var(--button-toggle-offset);
		transform: translateX(var(--button-toggle-offset));
		box-shadow: var(--toggle-shadow-offset) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
		transition: 0.3s all ease-in-out;
	}
}

.settings-appearance {
	width: 30vw;
	min-width: 300px;
	border-radius: 10px;
	border: 1px solid lightgray;
	padding: 20px;
}

.card {
	height: 100px;
	cursor: pointer;
  
	.mini-card {
	  opacity: 0.8;
	  height: 55px;
	  width: 90%;
	  margin: 15px auto 0;
	  background-color: rgba($color: $primary, $alpha: 0.3);
	  border-radius: 10px;
  
	  &.half {
		height: 13px;
		border-radius: 10px 10px 0 0;
		margin-bottom: 0;
	  }
  
	  .mini-rec {
		width: 65%;
		height: 6px;
		background-color: white;
		margin-left: 8px;
	  }
	}
  
	&.dark {
	  background-color: rgba($color: $secondary, $alpha: 1);
  
	  .mini-card {
		background-color: rgba($color: $primary, $alpha: 0.4);
	  }
  
	  .mini-rec {
		background-color: rgba($color: white, $alpha: 0.5);
	  }
  
	  &.color-blind-card{
		  background-color: rgb(42, 42, 42);
  
		  .mini-card {
			background-color: rgba(174, 174, 174, 0.4);
		  }
	  
		  .mini-rec {
			background-color: rgba(150, 150, 150, 0.5);
		  }
	  }
	}
  }